<template>
  <aw-modal
    size="md"
    :opened="opened"
    :closable="closable"
    :modal-title="expired ? $awt('aw.expire_timeframe.expired_title'):$awt('aw.expire_timeframe.expire_soon_title')"
    @close-modal="closeModal"
  >
    <template #content>
      <aw-separator margin-top="none" />
      <aw-notification-item
        :notification="{
          type: 'warning',
          iconName: 'maintenence-16',
          manualClose: false,
          text: {
            subtitle: expired ? $awt('aw.expire_timeframe.expired_description'):$awt('aw.expire_timeframe.expire_soon_description', { minute: checkoutReservationWarningPopupMinutes })
          }
        }"
        index="0"
        thick-content
      />
      <aw-separator />
      <div :class="$style.buttonContainer">
        <lv-button styling="primary-dark" shrink @click="closeModal">
          {{ $awt('aw.expire_timeframe.close') }}
        </lv-button>
      </div>
    </template>
  </aw-modal>
</template>
<script>
  import { mapState } from 'pinia';
  import { M_DELIVERY_TIMEFRAME_EXPIRED } from '~~/common/config/Modal.js';
  import { LvButton } from '~~/common/components/loginet-vue-shop/index.mjs';
  import AwModal from '~~/common/components/Common/AwModal';
  import AwSeparator from '~~/common/components/Common/AwSeparator';
  import AwNotificationItem from '~~/common/components/Common/AwNotificationItem.vue';

  import { useModalStore } from '~~/common/stores/modal';
  import { useConfigStore } from '~~/common/stores/config';
  import { useCheckoutStore } from '~~/common/stores/checkout';
  import { useDeliveryStore } from '~~/shop/stores/delivery';

  const beforeExpiredMinuteDefaultValue = 10;

  export default {
    name: 'AwExpireTimeframeModal',
    components: {
      AwNotificationItem,
      AwSeparator,
      AwModal,
      LvButton,
    },
    data () {
      return {
        expired: null,
        expireCheckInterval: null,
      };
    },
    computed: {
      ...mapState(useDeliveryStore, {
        expiredVisible: state => state.expiredTimeframeVisible.expiredVisible,
        expireSoonVisible: state => state.expiredTimeframeVisible.expireSoonVisible,
      }),
      ...mapState(useConfigStore, {
        checkoutReservationWarningPopupMinutes: state => state.configCommon?.persistentConfig?.checkout_reservation_warning_popup_minutes || beforeExpiredMinuteDefaultValue,
      }),
      ...mapState(useModalStore, {
        modal: state => state.activeGenericModal,
      }),
      ...mapState(useCheckoutStore, {
        checkout: state => state.getFoodCheckout,
      }),
      opened () {
        return this.modal.type === M_DELIVERY_TIMEFRAME_EXPIRED;
      },
      closable () {
        return this.modal.item.closable;
      },
      reservation () {
        return this.checkout?.reservation;
      },

    },
    mounted () {
      this.checkStatus();
      this.expireCheckInterval = setInterval(this.checkStatus, 20000);
    },
    beforeUnmount () {
      clearInterval(this.expireCheckInterval);
    },
    methods: {
      async closeModal () {
        const modalStore = useModalStore();
        const deliveryStore = useDeliveryStore();
        modalStore.closeExpireTimeFrameModal();
        // if expired === false ==> this.checkStatus already executed and else if part was true
        if (this.expired === false) {
          this.$cookies.set('time_frame_expired_soon_modal_closed', true, {
            path: '/',
            maxAge: 7 * 24 * 60 * 60,
          });
          deliveryStore.setExpireSoonVisible(true);
          // if expired === true ==> this.checkStatus already executed and if part was true
        } else if (this.expired === true) {
          deliveryStore.setExpiredVisible(true);
          deliveryStore.setExpireSoonVisible(true);
        }

        clearInterval(this.expireCheckInterval);
        await deliveryStore.fetchTimeframes();
        this.expireCheckInterval = setInterval(this.checkStatus, 20000);
      },
      checkStatus () {
        const modalStore = useModalStore();
        if (this.reservation && !this.reservation.original) {
          const expireDate = new Date(this.reservation.expiresAt);
          const currentDate = new Date();
          const expireSoonDate = this.checkoutReservationWarningPopupMinutes * 60 * 1000;
          const timeFrameExpiredSoonModalClosed = Boolean(this.$cookies.get('time_frame_expired_soon_modal_closed'));

          if (!this.expiredVisible && expireDate < currentDate) {
            this.expired = true;
            modalStore.openExpireTimeFrameModal();
            this.$cookies.set('time_frame_expired_soon_modal_closed', false, {
              path: '/',
              maxAge: 7 * 24 * 60 * 60,
            });
          } else if (!this.expireSoonVisible && !timeFrameExpiredSoonModalClosed && ((expireDate - expireSoonDate) < currentDate)) {
            this.expired = false;
            modalStore.openExpireTimeFrameModal();
          }
        }
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.timeframeModal {
  &Heading {
    padding-bottom: 24px;
    color: $color-brand-secondary;
  }

  &Close {
    padding-top: 32px;
    text-align: center;
  }
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
